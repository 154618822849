import moment from "moment";

const requestUrl = 'https://recruit-api.codetest.space/';
const publicToken = "dGVzdGNsaWVudDp0ZXN0cGFzcw=";

function userInfoAPI(token, callback) {
    $.ajax({
        url: requestUrl + "api/user",
        type: 'GET',
        beforeSend: (xhr) => {
            xhr.setRequestHeader("Authorization", "Bearer " + token)
        },
        dataType: "json",
        success:(response) => {
            callback(response, null);
        },
        error:(req, status, err) => {
            callback(null, err);
        }
    });
}

export function loginAPI(user, password, callback) {
    $.ajax({
        url: requestUrl + "token",
        type: 'POST',
        data: {
            grant_type: 'password',
            username: user,
            password: password
        },
        beforeSend: (xhr) => {
            xhr.setRequestHeader("Authorization", "Basic " + publicToken)
        },
        dataType: "json",
        success:(response) => {
            userInfoAPI(response.access_token, function (resp, err) {
                callback(response, null);
            })
        },
        error:(req, status, err) => {
            callback(null, err);
        }
    });
}

export function candidateCreateAPI(token, callback, email, firstname, lastname, phone) {
    $.ajax({
        url: requestUrl + "api/outlook/candidate",
        type: 'POST',
        data: JSON.stringify({
            Email: email,
            FirstName: firstname,
            LastName: lastname,
            Phone: phone,
            Source: "Outlook"
        }),
        dataType: "json",
        contentType: 'application/json',
        beforeSend: (xhr) => {
            xhr.setRequestHeader("Authorization", "Bearer " + token)
        },
        success:(response) => {
            callback(response, null);
        },
        error:(req, status, err) => {
            callback(null, err);
        }
    });
}

export function candidateInfoAPI(token, callback, email) {
    $.ajax({
        url: requestUrl + "api/outlook/candidate/find?Email=" + (email),
        type: 'GET',
        beforeSend: (xhr) => {
            xhr.setRequestHeader("Authorization", "Bearer " + token)
        },
        dataType: "json",
        success:(response) => {
            callback(response, null);
        },
        error:(req, status, err) => {
            callback(null, err);
        }
    });
}

export function candidateNotesAPI(token, callback, id) {
    $.ajax({
        url: requestUrl + "api/outlook/candidate/notes?id=" + (id),
        type: 'GET',
        beforeSend: (xhr) => {
            xhr.setRequestHeader("Authorization", "Bearer " + token)
        },
        dataType: "json",
        success:(response) => {
            callback(response, null);
        },
        error:(req, status, err) => {
            callback(null, err);
        }
    });
}

export function candidateCreateNotesAPI(token, callback, id, note) {
    $.ajax({
        url: requestUrl + "api/outlook/candidate/notes",
        type: 'POST',
        data: JSON.stringify({
            CandidateID: id,
            id: id,
            Note: note
        }),
        dataType: "json",
        contentType: 'application/json',
        beforeSend: (xhr) => {
            xhr.setRequestHeader("Authorization", "Bearer " + token)
        },
        success:(response) => {
            callback(response, null);
        },
        error:(req, status, err) => {
            callback(null, err);
        }
    });
}

export function candidateDeleteNotesAPI(token, callback, id) {
    $.ajax({
        url: requestUrl + "api/outlook/candidate/notes?CandidateNotesID=" + id,
        type: 'DELETE',
        dataType: "json",
        contentType: 'application/json',
        beforeSend: (xhr) => {
            xhr.setRequestHeader("Authorization", "Bearer " + token)
        },
        success:(response) => {
            callback(response, null);
        },
        error:(req, status, err) => {
            callback(null, err);
        }
    });
}

export function candidateTasksAPI(token, callback, id) {
    $.ajax({
        url: requestUrl + "api/outlook/candidate/tasks?CandidateID=" + (id),
        type: 'GET',
        beforeSend: (xhr) => {
            xhr.setRequestHeader("Authorization", "Bearer " + token)
        },
        dataType: "json",
        success:(response) => {
            callback(response, null);
        },
        error:(req, status, err) => {
            callback(null, err);
        }
    });
}

export function candidateCreateTaskAPI(token, callback, id, title, note, type) {
    $.ajax({
        url: requestUrl + "api/outlook/candidate/tasks",
        type: 'POST',
        data: JSON.stringify({
            CandidateID: id,
            TaskTitle: title,
            TaskComment: note,
            TaskTypeID: type ?? 1,
            TaskDate: moment().format("YYYY-MM-DD HH:mm:ss")
        }),
        dataType: "json",
        contentType: 'application/json',
        beforeSend: (xhr) => {
            xhr.setRequestHeader("Authorization", "Bearer " + token)
        },
        success:(response) => {
            callback(response, null);
        },
        error:(req, status, err) => {
            callback(null, err);
        }
    });
}

export function candidateDeleteTaskAPI(token, callback, id) {
    $.ajax({
        url: requestUrl + "api/outlook/candidate/tasks?TaskID=" + id,
        type: 'DELETE',
        dataType: "json",
        contentType: 'application/json',
        beforeSend: (xhr) => {
            xhr.setRequestHeader("Authorization", "Bearer " + token)
        },
        success:(response) => {
            callback(response, null);
        },
        error:(req, status, err) => {
            callback(null, err);
        }
    });
}

export function contactsAPI(token, callback, query = false) {
    $.ajax({
        url: requestUrl + "api/outlook/contacts/get?limit=20&offset=0&sortBy=ContactName&sort=ASC" + (!!query ? "&query=" + query : ""),
        type: 'GET',
        beforeSend: (xhr) => {
            xhr.setRequestHeader("Authorization", "Bearer " + token)
        },
        dataType: "json",
        success:(response) => {
            callback(response, null);
        },
        error:(req, status, err) => {
            callback(null, err);
        }
    });
}

export function clientsAPI(token, callback, query = false) {
    $.ajax({
        url: requestUrl + "api/outlook/contacts/get?limit=20&offset=0&sortBy=ContactName&sort=ASC&clientContacts=1" + (!!query ? "&query=" + query : ""),
        type: 'GET',
        beforeSend: (xhr) => {
            xhr.setRequestHeader("Authorization", "Bearer " + token)
        },
        dataType: "json",
        success:(response) => {
            callback(response, null);
        },
        error:(req, status, err) => {
            callback(null, err);
        }
    });
}

export function uploadAPI(token, id, subject, content, type, callback) {
    $.ajax({
        url: requestUrl + "api/outlook/send",
        type: 'POST',
        data: JSON.stringify({
            id: id,
            Message: content,
            Subject: subject,
            type: type
        }),
        dataType: "json",
        contentType: 'application/json',
        beforeSend: (xhr) => {
            xhr.setRequestHeader("Authorization", "Bearer " + token)
        },
        success:(response) => {
            callback(response, null);
        },
        error:(req, status, err) => {
            callback(null, err);
        }
    });
}

export function uploadSendAPI(token, id, subject, html, callback) {
    $.ajax({
        url: requestUrl + "api/outlook/import",
        type: 'POST',
        data: JSON.stringify({
            id: id,
            Subject: subject,
            Message: html
        }),
        dataType: "json",
        contentType: 'application/json',
        beforeSend: (xhr) => {
            xhr.setRequestHeader("Authorization", "Bearer " + token)
        },
        success:(response) => {
            callback(response, null);
        },
        error:(req, status, err) => {
            callback(null, err);
        }
    });
}

export function sendAttach(token, id, attach, type, callback) {
    $.ajax({
        url: requestUrl + "api/outlook/attach",
        type: 'POST',
        data: JSON.stringify({
            id: id,
            Attach: attach,
            type: type
        }),
        dataType: "json",
        contentType: 'application/json',
        beforeSend: (xhr) => {
            xhr.setRequestHeader("Authorization", "Bearer " + token)
        },
        success:(response) => {
            callback(response, null);
        },
        error:(req, status, err) => {
            callback(null, err);
        }
    });
}