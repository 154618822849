import {
    candidateCreateAPI,
    candidateCreateNotesAPI, candidateCreateTaskAPI, candidateDeleteNotesAPI, candidateDeleteTaskAPI,
    candidateInfoAPI, candidateNotesAPI, candidateTasksAPI,
    contactsAPI,
    loginAPI,
    sendAttach,
    uploadAPI,
    uploadSendAPI
} from '../helpers/api';
import {getConfig, setConfig} from "../helpers/addin-config";
import moment from "moment";

// Global state
let detectedEmail = null;
let selectedItem = null;
let selectedAttachments = {};
let attachBody = false;
let token = null;
let config;
let activeTab = 2;
let mode = 1;

// Office plugin loaded event
Office.onReady(info => {
    if ((info.host === Office.HostType.Outlook)) {
        init();
    }
});

function toFrontDateTime(dateEntered) {
    return (dateEntered ? moment(dateEntered, 'YYYY-MM-DD HH:mm:ss').format("MM/DD/YYYY h:mm a") : "No Date Data");
}

function init() {
    document.getElementById("app-body").style.display = "block";
    document.getElementById("login-btn").onclick = login;
    document.getElementById("upload-btn").onclick = upload;
    document.getElementById("upload-done").onclick = done;

    document.getElementById("send-btn").onclick = uploadSend;

    document.getElementById("logout").onclick = logout;
    document.getElementById("loginform").onsubmit = login;
    $('#loginform input').keypress(function (e) {
        if (e.which === 13) {
            login();
            return false;
        }
    });

    config = getConfig();
    if (config && config.token) {
        token = config.token;
        const expires = Math.round(token.expires_in * (7 / 10));
        const currTime = getCurrentTimeSeconds();
        if ((token.obtain_time + expires) > currTime) {
            loginInit();
        } else {
            logout();
        }
    }
}

function loginInit() {
    document.getElementById("upload").style.display = "block";
    document.getElementById("login").style.display = "none";
    document.getElementById("upload-done").style.display = "none";
    document.getElementById("logout").style.display = "block";

    if (!!Office.context.mailbox.item.from.emailAddress) {
        mode = 1;
        detectedEmail = Office.context.mailbox.item.from.emailAddress;
        loadInit();
    } else {
        mode = 2;
        Office.context.mailbox.item.to.getAsync((data) => {
            detectedEmail = data.value[0].emailAddress;
            loadInit();
        })
    }
}

function loadInit() {
    $("#attach-body").on('change', function () {
        attachBody = !!$(this).is(":checked");
    });

    $("#search").val(detectedEmail);

    $("#infoTab").on('click', function () {
        msg("");

        $('.system-tab').removeClass("active");
        $(this).addClass('active');

        loadCandidate();
        activeTab = 2;

        $(".messageComposeMode").addClass("ninja");
        $(".messageReadMode").addClass("ninja");
    }).trigger("click");

    $("#notesTab").on('click', function () {
        msg("");

        if (!selectedItem) {
            msg("Candidate must be created before accessing this tab.")
            return;
        }
        $('.system-tab').removeClass("active");
        $(this).addClass('active');
        loadCandidateNotes();
        activeTab = 3;

        $(".messageComposeMode").addClass("ninja");
        $(".messageReadMode").addClass("ninja");
    });

    $("#tasksTab").on('click', function () {
        msg("");

        if (!selectedItem) {
            msg("Candidate must be created before accessing this tab.")
            return;
        }

        $('.system-tab').removeClass("active");
        $(this).addClass('active');
        loadCandidateTasks();
        activeTab = 4;

        $(".messageComposeMode").addClass("ninja");
        $(".messageReadMode").addClass("ninja");
    })

    $("#contactsTab").on('click', function () {
        msg("");

        if (!selectedItem) {
            msg("Candidate must be created before accessing this tab.")
            return;
        }

        $('.system-tab').removeClass("active");
        $(this).addClass('active');

        $("#contacts").html("");

        if (mode === 1) {
            buildAttach();
            $(".messageComposeMode").addClass("ninja");
            $(".messageReadMode").removeClass("ninja");
        } else {
            $(".messageComposeMode").removeClass("ninja");
            $(".messageReadMode").addClass("ninja");
        }
        activeTab = 1;
    });
}

function logout() {
    document.getElementById("upload").style.display = "none";
    document.getElementById("login").style.display = "block";
    document.getElementById("upload-done").style.display = "none";
    document.getElementById("logout").style.display = "none";
    setConfig({}, function () {})
}

function getCurrentTimeSeconds() {
    const d = new Date();
    return Math.round(d.getTime() / 1000);
}

function login() {
    loader(true);
    msg("");
    const username = document.getElementById("username").value;
    const password = document.getElementById("password").value;
    loginAPI(username, password, function (result, error) {
          if (error === null) {
              token = result;
              token.obtain_time = getCurrentTimeSeconds();
              setConfig({token: token}, function () {
                  loginInit(token);
              });
          } else {
              msg("Invalid username or password.");
              loader(false);
          }
      });
}

function loadContacts() {
    selectedItem = null;
    selectedAttachments = {};
    attachBody = null;

    $("#attach-body").prop("checked", false);

    loader(true);
    const query = document.getElementById("search").value;

    contactsAPI(token.access_token, function (res, err) {
        loader(false);
        if (err === null) {
            buildListContacts(res.data.list, function (item, jItem) {
                selectedItem = item;
                $("#contacts .contactItem").removeClass("selected");
                jItem.addClass("selected");
                if (mode === 2) {
                    Office.context.mailbox.item.to.setAsync([item.Email], (res) => {
                        const message = {
                            type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
                            message: "Email sent to " + item.Email + " will copied to the GoLeir system as well.",
                            icon: "Icon.80x80",
                            persistent: true
                        }

                        // Show a notification message
                        Office.context.mailbox.item.notificationMessages.addAsync("emailto", message);
                    });
                }
            });
        } else {
            msg(JSON.stringify(err));
        }
    }, query);
}

function loadCandidate()
{
    loader(true);
    candidateInfoAPI(token.access_token, function (res, err) {
        loader(false);
        if (err === null) {
            $("#contacts").html("");
            if (res.data) {
                selectedItem = res.data;
                $("#contacts").append(
                    "<h2>"+res.data.FirstName+ " " + res.data.LastName + "</h2>"
                    + "<p>Credentials: " + (res.data.Credentials ?? "/") +"</p>"
                    + "<p>Title: " + (res.data.Title ?? "/") +"</p>"
                    + "<p>Headline: " + (res.data.Headline ?? "/") +"</p>"
                    + "<p>Address: " + (res.data.Address1 ?? "/") +"</p>"
                    + "<p>City: " + (res.data.City ?? "/") +"</p>"
                    + "<p>Phone: " + (res.data.Phone ?? "/") +"</p>"
                    + "<p>Linkedin: " + (res.data.LinkedinProfileURL ?? "/") +"</p>"
                );
            } else {
                $("#contacts").append('<div><p>Candidate with the given email not found, create one?</p></div>' +
                    '<div class="input-wrapper">' +
                    '  <div class="input-field">' +
                    '     <label for="username">First Name</label><input id="firstname" type="text" name="firstname" placeholder="First Name" value="">' +
                    ' </div>' +
                    '</div>' +
                    '<div class="input-wrapper">' +
                    '  <div class="input-field">' +
                    '     <label for="username">Last Name</label><input id="lastname" type="text" name="lastname" placeholder="Last Name" value="">' +
                    '  </div>' +
                    '</div>' +
                    '<div class="input-wrapper">' +
                    '  <div class="input-field">' +
                    '     <label for="username">Phone</label><input id="phone" type="text" name="phone" placeholder="Phone" value="">' +
                    '  </div>' +
                    '</div>' +
                    '<div role="button" id="create-btn" class="general-btn">' +
                    '   <span class="ms-Button-label">Create</span>' +
                    '</div>'
                );

                $("#create-btn").on('click', function () {
                    const fn = $("#firstname").val();
                    const ln = $("#lastname").val();
                    if (!fn || !ln) {
                        msg("You must enter first name, and last name.")
                    } else {
                        createCandidate(detectedEmail, fn, ln, $("#phone").val());
                    }
                });

                msg();
            }
        } else {
            msg(JSON.stringify(err));
        }
    }, detectedEmail);
}

function createCandidate(email, firstname, lastname, phone)
{
    loader(true);
    candidateCreateAPI(token.access_token, function (res, err) {
        loader(false);
        if (err === null) {
            loadCandidate();
            const message = {
                type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
                message: "Candidate created",
                icon: "Icon.80x80",
                persistent: true
            }

            // Show a notification message
            Office.context.mailbox.item.notificationMessages.addAsync("emailto", message);
        } else {
            msg(JSON.stringify(err));
        }
    }, email, firstname, lastname, phone);
}

function loadCandidateNotes()
{
    loader(true);
    $("#contacts").html('<div class="notesForm"><form name="notesForm">' +
        ' <input type="text" name="newnote"  id="newnote" placeholder="Enter your notes here ..." required />' +
        ' <button type="submit" class="form-button" id="add-note-btn">Add Note</button>' +
        ' </form></div><div class="noteslist">' +
        '<ul></ul>');

    $("#add-note-btn").on('click', function (e) {
        e.preventDefault();
        if ($('#newnote').val().length === 0) {
            msg("Please enter note text.");
        } else {
            createNote();
        }
    })

    candidateNotesAPI(token.access_token, function (res, err) {
        loader(false);
        if (err === null) {
            res.data.list.forEach((it, i) => {
                $(".noteslist")
                    .append('<li>' +
                        '<div class="each-note ">' +
                        '<div>' +
                        '<div class="note-text">'+it.FirstName+ ' ' +it.LastName+' ('+toFrontDateTime(it.CreateUpdateDate)+')</div>' +
                        '<div class="note-text">'+it.Note+'</div>' +
                        '</div>' +
                        '<i class="fa fa-trash del-icon del-note'+it.CandidateNotesID+'"></i>' +
                        '</span>' +
                        '</div>' +
                ' </li>');

                $(".del-note" + it.CandidateNotesID).on('click', function () {
                    deleteNote(it.CandidateNotesID);
                });
            });

            msg("");
        } else {
            msg(JSON.stringify(err));
        }
    }, selectedItem.CandidateID);
}

function createNote()
{
    loader(true);
    candidateCreateNotesAPI(token.access_token, function (res, err) {
        loader(false);
        if (err === null) {
            loadCandidateNotes();
            const message = {
                type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
                message: "Note created",
                icon: "Icon.80x80",
                persistent: true
            }

            // Show a notification message
            Office.context.mailbox.item.notificationMessages.addAsync("emailto", message);
        } else {
            msg(JSON.stringify(err));
        }
    }, selectedItem.CandidateID, $('#newnote').val());
}

function deleteNote(NoteID)
{
    loader(true);
    candidateDeleteNotesAPI(token.access_token, function (res, err) {
        loader(false);
        if (err === null) {
            loadCandidateNotes();
            const message = {
                type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
                message: "Note deleted",
                icon: "Icon.80x80",
                persistent: true
            }

            // Show a notification message
            Office.context.mailbox.item.notificationMessages.addAsync("emailto", message);
        } else {
            msg(JSON.stringify(err));
        }
    }, NoteID);
}

function loadCandidateTasks()
{
    loader(true);
    $("#contacts").html('<div class="notesForm"><form name="notesForm">' +
        ' <input type="text" name="newtask"  id="newtask" placeholder="Enter title here" required />' +
        ' <textarea name="newtaskdesc"  id="newtaskdesc" placeholder="Enter description here" required></textarea>' +
        '<select id="newtasktype"><option value="1">Task</option>' +
        '<option value="2">Interview</option></select>'+
        ' <input type="text" name="newtaskdate"  id="newtaskdate" placeholder="Select due date"/>' +
        ' <button type="submit" class="form-button" id="add-task-btn">Add Task</button>' +
        ' </form></div><div class="noteslist">' +
        '<ul></ul>');

    $( "#newtaskdate" ).datepicker({"dateFormat": "mm/dd/yy"});

    $("#add-task-btn").on('click', function (e) {
        e.preventDefault();
        if (($('#newtask').val().length === 0) || ($('#newtaskdesc').val().length === 0)) {
            msg("Please enter title text, and description.");
        } else {
            createTask();
        }
    })

    candidateTasksAPI(token.access_token, function (res, err) {
        loader(false);
        if (err === null) {
            res.data.list.forEach((it, i) => {
                $(".noteslist")
                    .append('<li>' +
                        '<div class="each-note ">' +
                        '<div>' +
                        '<div class="note-text">'+it.TaskTitle+' ('+toFrontDateTime(it.TaskDate)+')</div>' +
                        '<div class="note-text">'+it.TaskComment+'</div>' +
                        '</div>' +
                        '<i class="fa fa-trash del-icon del-task'+it.TaskID+'"></i>' +
                        '</span>' +
                        '</div>' +
                        ' </li>');
                $(".del-task" + it.TaskID).on('click', function () {
                    deleteTask(it.TaskID);
                });
            });
        } else {
            msg(JSON.stringify(err));
        }
    }, selectedItem.CandidateID);
}

function createTask()
{
    loader(true);
    candidateCreateTaskAPI(token.access_token, function (res, err) {
        loader(false);
        if (err === null) {
            loadCandidateTasks();
            const message = {
                type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
                message: "Task created",
                icon: "Icon.80x80",
                persistent: true
            }

            // Show a notification message
            Office.context.mailbox.item.notificationMessages.addAsync("emailto", message);
        } else {
            msg(JSON.stringify(err));
        }
    }, selectedItem.CandidateID, $('#newtask').val(), $('#newtaskdesc').val(), $("#newtasktype").val());
}

function deleteTask(TaskID)
{
    loader(true);
    candidateDeleteTaskAPI(token.access_token, function (res, err) {
        loader(false);
        if (err === null) {
            loadCandidateTasks();
            const message = {
                type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
                message: "Task deleted",
                icon: "Icon.80x80",
                persistent: true
            }

            // Show a notification message
            Office.context.mailbox.item.notificationMessages.addAsync("emailto", message);
        } else {
            msg(JSON.stringify(err));
        }
    }, TaskID);
}

function upload() {
    if (!selectedItem || (Object.keys(selectedAttachments).length === 0) && !attachBody) {
        msg("You must select one of attachments for import.");
        return;
    }

    msg("");

    loader(true);

    if (attachBody) {
        loader(true);
        Office.context.mailbox.item.body.getAsync(Office.CoercionType.Text, function (bodyResult) {
            uploadAPI(token.access_token, selectedItem.CandidateID, Office.context.mailbox.item.subject, bodyResult.value, activeTab, function (response, error) {
                uploadDone(error);
            });
        });
    }
    if (Object.keys(selectedAttachments).length > 0) {
        Office.context.mailbox.getCallbackTokenAsync({isRest: false}, function (asyncResult) {
            if (asyncResult.status === "succeeded") {
                var attach = {
                    url: Office.context.mailbox.ewsUrl,
                    token: asyncResult.value,
                    list: Office.context.mailbox.item.attachments,
                    selection: selectedAttachments
                };
                sendAttach(token.access_token, selectedItem.CandidateID, attach, activeTab, function (response, error) {
                    uploadDone(error);
                });
            } else {
                msg(asyncResult.error.message);
                loader(false);
            }
        });
    }
}

function uploadDone(error) {
    if (!error) {
        selectedItem = null;
        selectedAttachments = {};
        attachBody = null;
        $(".contactItem").removeClass("selected");
        $("#attach-body").prop("checked", false);
        buildAttach();
        document.getElementById("upload").style.display = "none";
        document.getElementById("login").style.display = "none";
        document.getElementById("upload-done").style.display = "block";

        const message = {
            type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
            message: "Email imported to GoLeir system",
            icon: "Icon.80x80",
            persistent: true
        }

        // Show a notification message
        Office.context.mailbox.item.notificationMessages.addAsync("emailto", message);
    } else {
        msg("Error while sending attachment: " + JSON.stringify(error));
    }
    loader(false);
}

function uploadSend() {
    Office.context.mailbox.item.body.getAsync(Office.CoercionType.Text, function (bodyResult) {
        const html = bodyResult.value;
        loader(true);
        uploadSendAPI(token.access_token, selectedItem.CandidateID, Office.context.mailbox.item.subject, html, (res, error) => {
            uploadSendDone(error);
        })
    });
}

function uploadSendDone(error) {
    if (!error) {
        selectedItem = null;
        $(".contactItem").removeClass("selected");

        document.getElementById("upload").style.display = "none";
        document.getElementById("login").style.display = "none";
        document.getElementById("upload-done").style.display = "block";

        const message = {
            type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
            message: "Email imported to GoLeir system",
            icon: "Icon.80x80",
            persistent: true
        }

        // Show a notification message
        Office.context.mailbox.item.notificationMessages.addAsync("emailto", message);
    } else {
        msg("Error while sending attachment: " + JSON.stringify(error));
    }
    loader(false);
}

function done() {
    document.getElementById("upload").style.display = "block";
    document.getElementById("login").style.display = "none";
    document.getElementById("upload-done").style.display = "none";
}

function msg(msg) {
    if (msg) {
        document.getElementById("message").style.display = "block";
        document.getElementById("message").innerHTML = msg;
    } else {
        document.getElementById("message").style.display = "none";
    }
}

function loader(show) {
    if (show) {
        document.getElementById("loader").style.display = "block";
    } else {
        document.getElementById("loader").style.display = "none";
    }
}

function buildListContacts(data, clickFunc) {
    $("#contacts").html("");
    data.forEach(function (item) {
        var listItem = $('<div class="contactItem contact_' + item.CandidateID + '">' + item.FirstName + ' ' + item.LastName + ' (' + item.Email + ')</div>');
        listItem.on('click', function () {
            clickFunc(item, listItem);
        });
        $("#contacts").append(listItem)
    });

    if (data.length === 0) {
        $("#contacts").append("No results found!");
    }
    buildAttach();
}

function buildAttach() {
    $("#attach").html("");

    var at = Office.context.mailbox.item.attachments;
    at.forEach(function (it, i) {
        var jItem = $('<div class="item"><span>' + it.name + '</span><select id="at_' + i + '" name="at_' + i + '"><option value="0">Don\'t import</option>' +
            '<option value="1">Letter</option><option value="2">Resume</option><option value="3">Biography</option>'
            + '</select></div>');
        $("#attach").append(jItem);

        $("#at_" + i).on('change', function () {
            selectedAttachments[i] = $("#at_" + i).val();
        });
    });
    if (at.length === 0) {
        $("#attach").append("No attachments!");
    }
}
